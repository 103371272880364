import { DeepPartial } from '@empiriecom/module-components/utils/interfaces';
import deepmerge from '@/src/utils/deepmergeconfig';
import { tenantConfig } from '@empiriecom/mybuy-components/config/jelmolich';
import defaultConfig from './default';
import { FragmentConfig } from './types';

const jelmolichconfig: DeepPartial<FragmentConfig> = {
  ...tenantConfig,
  locales: ['de-CH', 'fr-CH'],
  l10n: {
    'de-CH': ['base-de', 'jelmolich-de'],
    'fr-CH': ['base-fr', 'jelmolich-fr'],
  },
  companyId: 'jelmolich',
  tracking: {
    gtmId: 'GTM-5XXTXXK',
    soastaId: 'E5TKR-EUQHN-KSJKK-TGX8P-4US3H',
  },
  permissions: {
    serviceMailEnabled: false,
    newsletterEnabled: false,
  },
  show: {
    ...tenantConfig.show,
    bic: false,
    lastName: true,
  },
  images: {
    logoSmall: {
      default: 'https://bilder.baur.de/i/empiriecom/jelmoli_logo_small.png',
    },
  },
  flexikonto: {
    enabled: true,
    displayMinimumRate: false,
    externalUrls: {
      faq: 'https://fragment-content-external.jelmolich.empirie.cloud/de/mein-konto/mein-flexikonto',
      paymentPause: {
        default: '/service-hilfe/bezahlung-finanzierung/flexikonto-zahlpause',
        'fr-CH': '/fr/aide-service/paiement-financement/flexikonto-pause-de-paiement',
      },
      paymentProtection: {
        default: '/service-hilfe/bezahlung-finanzierung/flexikonto-restschutz',
        'fr-CH': '/fr/aide-service/paiement-financement/flexikonto-protection-de-paiement/',
      },
    },
  },
  returnLabelShown: true,
  checkMarkColor: 'primary',
};

export default deepmerge(defaultConfig, jelmolichconfig) as FragmentConfig;
